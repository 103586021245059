<template>
  <div class="work">
    <b-jumbotron text-variant="dark">
      <template slot="header">
        Contact
        <i class="fas fa-paper-plane"></i>
      </template>
    </b-jumbotron>
    <div class="row justify-content-md-center">
      <div class="col-sm-6 mb-3 text-left">
        <h2>Email</h2>
        <b-form-input type="email" value="rorenge7@gmail.com"></b-form-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
