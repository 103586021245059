var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "b-jumbotron",
        { attrs: { "text-variant": "dark" } },
        [
          _c("template", { slot: "header" }, [
            _vm._v("\n      Home\n      "),
            _c("i", { staticClass: "fas fa-home" })
          ]),
          _vm._v(" "),
          _c("b-card-text", [
            _c("strong", [_vm._v("Rorenge")]),
            _vm._v("のポートフォリオサイトです．\n    ")
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-6 mb-3" },
          [
            _c(
              "router-link",
              { staticClass: "card-link", attrs: { to: "/profile" } },
              [
                _c(
                  "b-card",
                  { attrs: { "bg-variant": "light" } },
                  [
                    _c("h2", [
                      _vm._v("\n            Profile\n            "),
                      _c("i", { staticClass: "fas fa-id-card" })
                    ]),
                    _vm._v(" "),
                    _c("b-card-text", [_vm._v("Rorengeについて")])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-6 mb-3" },
          [
            _c(
              "router-link",
              { staticClass: "card-link", attrs: { to: "/skill" } },
              [
                _c(
                  "b-card",
                  { attrs: { "bg-variant": "light" } },
                  [
                    _c("h2", [
                      _vm._v("\n            Skill\n            "),
                      _c("i", { staticClass: "fas fa-star" })
                    ]),
                    _vm._v(" "),
                    _c("b-card-text", [_vm._v("できること")])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-6 mb-3" },
          [
            _c(
              "router-link",
              { staticClass: "card-link", attrs: { to: "/work" } },
              [
                _c(
                  "b-card",
                  { attrs: { "bg-variant": "light" } },
                  [
                    _c("h2", [
                      _vm._v("\n            Work\n            "),
                      _c("i", { staticClass: "fas fa-business-time" })
                    ]),
                    _vm._v(" "),
                    _c("b-card-text", [_vm._v("やってきたこと")])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-6 mb-3" },
          [
            _c(
              "router-link",
              { staticClass: "card-link", attrs: { to: "/contact" } },
              [
                _c(
                  "b-card",
                  { attrs: { "bg-variant": "light" } },
                  [
                    _c("h2", [
                      _vm._v("\n            Contact\n            "),
                      _c("i", { staticClass: "fas fa-paper-plane" })
                    ]),
                    _vm._v(" "),
                    _c("b-card-text", [_vm._v("れんらくしたいとき")])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }