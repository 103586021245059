var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "pt-4 pt-md-5 border-top bg-light fixed-bottom" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md" }, [
          _c("img", {
            staticClass: "mb-2",
            attrs: {
              src: require("../assets/logo.png"),
              alt: "",
              width: "48",
              height: "48"
            }
          }),
          _vm._v(" "),
          _c("small", { staticClass: "d-block text-muted" }, [
            _vm._v("Copyright © 2019 Rorenge. All Rights Reserved.")
          ]),
          _vm._v(" "),
          _c("small", { staticClass: "d-block mb-3 text-muted" }, [
            _vm._v("Updated at " + _vm._s(_vm.updatedAtStr))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md" }, [
          _c(
            "h5",
            [
              _c(
                "router-link",
                { staticClass: "text-muted", attrs: { to: "/" } },
                [_vm._v("Rorenge Portfolio")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "list-unstyled text-small" }, [
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { staticClass: "text-muted", attrs: { to: "/profile" } },
                  [_vm._v("Profile")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { staticClass: "text-muted", attrs: { to: "/skill" } },
                  [_vm._v("Skill")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { staticClass: "text-muted", attrs: { to: "/work" } },
                  [_vm._v("Work")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { staticClass: "text-muted", attrs: { to: "/contact" } },
                  [_vm._v("Contact")]
                )
              ],
              1
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }