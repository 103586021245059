var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work" },
    [
      _c(
        "b-jumbotron",
        { attrs: { "text-variant": "dark" } },
        [
          _c("template", { slot: "header" }, [
            _vm._v("\n      Contact\n      "),
            _c("i", { staticClass: "fas fa-paper-plane" })
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c(
          "div",
          { staticClass: "col-sm-6 mb-3 text-left" },
          [
            _c("h2", [_vm._v("Email")]),
            _vm._v(" "),
            _c("b-form-input", {
              attrs: { type: "email", value: "rorenge7@gmail.com" }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }