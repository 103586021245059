var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile" },
    [
      _c(
        "b-jumbotron",
        { attrs: { "text-variant": "dark" } },
        [
          _c("template", { slot: "header" }, [
            _vm._v("\n      Profile\n      "),
            _c("i", { staticClass: "fas fa-id-card" })
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "media" }, [
        _c("img", {
          staticClass: "mr-3",
          attrs: { src: require("../assets/logo.png"), alt: "Rorenge" }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "media-body" },
          [
            _c("h1", [_vm._v("Rorenge")]),
            _vm._v(" "),
            _c("b-form", [
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-4 col-form-label" }, [
                  _vm._v("出身地")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-8" },
                  [
                    _c("b-form-input", {
                      staticClass: "bg-light",
                      attrs: { type: "text", readonly: "", value: "東京" }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-4 col-form-label" }, [
                  _vm._v("生年")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-8" },
                  [
                    _c("b-form-input", {
                      staticClass: "bg-light",
                      attrs: { type: "text", readonly: "", value: "1995" }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-4 col-form-label" }, [
                  _vm._v("役割")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-8" },
                  [
                    _c("b-form-input", {
                      staticClass: "bg-light",
                      attrs: {
                        type: "text",
                        readonly: "",
                        value: "Webエンジニア"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-4 col-form-label" }, [
                  _vm._v("身分")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-8" },
                  [
                    _c("b-form-input", {
                      staticClass: "bg-light",
                      attrs: {
                        type: "text",
                        readonly: "",
                        value: "フリーランス"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group row" }, [
                _c("label", { staticClass: "col-sm-4 col-form-label" }, [
                  _vm._v("できること")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-8" },
                  [
                    _c("b-form-input", {
                      staticClass: "bg-light",
                      attrs: {
                        type: "text",
                        readonly: "",
                        value: "Webサービス開発・運用"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }